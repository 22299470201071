// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { string, arrayOf, shape } from 'prop-types';

import { H1, Section, P, Link, Ul, Li, H2, Img } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function PartnersContainer({ title, subtitle, id }) {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          fields: { sourceName: { eq: "partners" } }
          frontmatter: { hidden: { ne: true } }
        }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              type
              url
              logo {
                ...LogoFragment
              }
            }
          }
        }
      }
    }
  `);

  const sortedPartners = allMdx.edges.reduce((acc, { node: { frontmatter: partner } }) => {
    acc[partner.type] = [...(acc[partner.type] || []), partner];
    return acc;
  }, {});

  return (
    <Section
      id={id}
      gridColumn="2"
      padding={{
        xs: '5rem 0',
        md: '10vh 0',
        lg: '15vh 0',
      }}
    >
      <H1
        fontSize={{
          xs: '6rem',
          lg: '8rem',
        }}
        lineHeight="1"
        fontWeight="700"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 2rem"
      >
        {title}
      </H1>
      <P
        fontSize="4rem"
        lineHeight="1"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 4rem"
      >
        {subtitle}
      </P>
      <Ul>
        {Object.entries(sortedPartners).map(([category, data]) => (
          <Li key={category}>
            <H2 fontSize="2.5rem" letterSpacing="-0.025em" textAlign="center">
              {category}
            </H2>
            <Ul display="flex" flexWrap="wrap" justifyContent="center" margin="0 -1rem 4rem">
              {data.map((partner) => (
                <Li
                  key={partner.title}
                  flex={category === 'Titulární partner' ? '0 0 48rem' : '0 0 32rem'}
                  margin="1rem"
                >
                  <Link to={partner.url} title={partner.title}>
                    <Img
                      {...partner?.logo?.childImageSharp?.fluid}
                      alt={`${partner.title} logo`}
                      ratio={1 / 2}
                      backgroundColor="var(--color-inverse)"
                      css={`
                        --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
                        box-shadow: var(--shadow);

                        &:hover {
                          --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.1);
                        }
                      `}
                      borderRadius="1rem"
                      imgProps={{
                        padding: category === 'Titulární partner' ? '9rem 8rem' : '4rem 8rem',
                        objectFit: 'contain',
                      }}
                    />
                  </Link>
                </Li>
              ))}
            </Ul>
          </Li>
        ))}
      </Ul>
    </Section>
  );
}

PartnersContainer.propTypes = {
  title: string.isRequired,
  subtitle: string,
  // body:     string,
  // buttons:  arrayOf(
  //   shape({
  //     title: string.isRequired,
  //     url:   string.isRequired,
  //     look:  string.isRequired,
  //   }),
  // ),
  // icons: arrayOf(
  //   shape({
  //     title: string.isRequired,
  //     url:   string.isRequired,
  //     icon:  string.isRequired,
  //   }),
  // ),
};

PartnersContainer.defaultProps = {
  subtitle: '',
  // body:     '',
  // buttons:  [],
  // icons:    [],
};
