// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaPhoneSquare, FaEnvelopeSquare } from 'react-icons/fa';
import { string } from 'prop-types';

import { H1, Section, P, Ul, Li, H2, Img, Link, View } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function PeopleContainer({ title, subtitle, body, id, filter }) {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fields: { sourceName: { eq: "people" } }, frontmatter: { hidden: { ne: true } } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              position
              company
              description
              image {
                ...PersonImageFragment
              }
              personType
              contact {
                email
                phone
              }
            }
          }
        }
      }
    }
  `);

  const filteredPeople = allMdx.edges.reduce((acc, { node: { frontmatter: person } }) => {
    if (person.personType.some((type) => type === filter)) {
      return [...acc, person];
    }
    return acc;
  }, []);

  return (
    <Section
      id={id}
      gridColumn="2"
      padding={{
        xs: '5rem 0',
        md: '10vh 0',
        lg: '15vh 0',
      }}
    >
      <H1
        fontSize={{
          xs: '6rem',
          lg: '8rem',
        }}
        lineHeight="1"
        fontWeight="700"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 2rem"
      >
        {title}
      </H1>
      <P
        fontSize="4rem"
        lineHeight="1"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 4rem"
      >
        {subtitle}
      </P>
      {body && <P>{body}</P>}
      <Ul gridTemplateColumns="repeat(auto-fit, minmax(25rem, 1fr))" gridGap="2rem">
        {filteredPeople.map((person) => (
          <Li
            key={person.title}
            position="relative"
            borderRadius="1rem"
            backgroundColor="var(--color-inverse)"
            css={`
              --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
              box-shadow: var(--shadow);
            `}
            overflow="hidden"
            hover={{
              '& p': {
                opacity: '1',
              },
            }}
          >
            <View position="relative">
              <Img
                {...person?.image?.childImageSharp?.fluid}
                alt={`Portrét ${person.title}`}
                ratio={4 / 3}
                css={
                  filter === 'Tým' &&
                  `
                  filter: grayscale();
                `
                }
              />
              {person.description && filter !== 'Tým' && (
                <P
                  opacity="0"
                  transition="opacity 250ms"
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="0"
                  right="0"
                  backgroundImage="linear-gradient(45deg, var(--color-brand-primary), var(--color-brand-secondary))"
                  color="var(--color-inverse)"
                  overflow="scroll"
                  padding="2rem"
                  fontSize="2.5rem"
                  lineHeight="3rem"
                >
                  {person.description}
                </P>
              )}
            </View>
            <H2 fontSize="2.5rem" fontWeight="700" padding="1rem 1rem 0">
              {person.title}
            </H2>
            <P lineHeight="1" padding="0 1rem 1rem">
              {person.position}
              {person.position && person.company && filter !== 'Tým' && ', '}
              {filter !== 'Tým' && person.company}
            </P>
            {person.contact && filter === 'Tým' && (
              <>
                <Link
                  to={person.contact.phone}
                  look="primary"
                  display="block"
                  lineHeight="1"
                  fontWeight="400"
                  padding="0 1rem 1rem"
                  margin="1rem 0 0"
                >
                  <FaPhoneSquare fontSize="1.5rem" /> {person.contact.phone}
                </Link>
                <Link
                  to={person.contact.email}
                  look="primary"
                  display="block"
                  fontWeight="400"
                  lineHeight="1"
                  padding="0 1rem 1rem"
                >
                  <FaEnvelopeSquare fontSize="1.5rem" /> {person.contact.email}
                </Link>
              </>
            )}
          </Li>
        ))}
      </Ul>
    </Section>
  );
}

PeopleContainer.propTypes = {
  title: string.isRequired,
  subtitle: string,
  body: string,
  filter: string,
};

PeopleContainer.defaultProps = {
  subtitle: '',
  body: '',
  filter: '',
};
