// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import styled from 'styled-components';

import { View } from '~components/primitives/View';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export const Form = styled(View)`
  display: grid;
  grid-gap: 1rem;
`;
Form.defaultProps = { as: 'form', gridArea: 'form' };
