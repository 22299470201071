// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { string, arrayOf, shape } from 'prop-types';

import { H1, Section, P, Button, Link, Ul, Li, H2, View } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function BlogContainer({ title, subtitle, body, id, buttons, layout }) {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fields: { sourceName: { eq: "posts" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              tags
              meta {
                permalink
              }
              highlighted
            }
          }
        }
      }
    }
  `);

  const blogPosts = allMdx.edges.reduce((acc, item) => {
    const article = item.node.frontmatter;
    if (layout !== 'full') {
      if (article.highlighted) {
        return [...acc, article];
      }
      return acc;
    }

    if (article.tags) {
      return [...acc, article];
    }

    return acc;
  }, []);

  return (
    <Section
      id={id}
      gridColumn="2"
      display="grid"
      gridTemplateColumns={
        layout === 'full'
          ? '1fr'
          : {
            xs: '1fr',
            lg: '1fr 1fr',
          }
      }
      gridGap="0 10rem"
      alignItems="center"
      padding={{
        xs: '5rem 0',
        md: '10vh 0',
        lg: '15vh 0',
      }}
    >
      <View
        order={layout === 'right' && { lg: '2' }}
        margin={{ xs: '0 0 4rem', lg: '0' }}
        textAlign={{
          xs: '',
          lg: layout === 'right' ? undefined : layout === 'full' ? 'center' : 'end',
        }}
      >
        <H1
          fontSize={{
            xs: '6rem',
            lg: '8rem',
          }}
          lineHeight="1"
          fontWeight="700"
          letterSpacing="-0.025em"
          margin="0 0 2rem"
        >
          {title}
        </H1>
        {subtitle && (
          <P fontSize="4rem" lineHeight="1" letterSpacing="-0.025em" margin="0 0 4rem">
            {subtitle}
          </P>
        )}
        {body && <P>{body}</P>}
        {buttons?.length > 0
          && buttons.map(button => (
            <Button
              key={button.url}
              as={Link}
              to={button.url}
              look={button.look}
              display="inline-block"
              grouped
            >
              {button.title}
            </Button>
          ))}
      </View>
      <Ul
        gridGap="1rem"
        justifyItems="end"
        gridTemplateColumns={layout === 'full' && 'repeat(auto-fit, minmax(40rem, 1fr))'}
        textAlign={layout === 'right' && 'end'}
      >
        {blogPosts.map(post => (
          <Li
            // textAlign="end"
            key={post.meta.permalink}
            padding="2rem"
            backgroundColor="var(--color-inverse)"
            css={`
              --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
              box-shadow: var(--shadow);
            `}
            borderRadius="1rem"
          >
            <P textTransform="uppercase" letterSpacing="0.1em" opacity="0.75">
              {post.tags}
            </P>
            <H2 fontSize="4rem">
              <Link to={post.meta.permalink} look="primary">
                {post.title}
              </Link>
            </H2>
          </Li>
        ))}
      </Ul>
    </Section>
  );
}

BlogContainer.propTypes = {
  title:    string.isRequired,
  subtitle: string,
  body:     string,
  buttons:  arrayOf(
    shape({
      title: string.isRequired,
      url:   string.isRequired,
      look:  string.isRequired,
    }),
  ),
  layout: string,
};

BlogContainer.defaultProps = {
  subtitle: '',
  body:     '',
  buttons:  [],
  layout:   'left',
};
