// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { string, node } from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled, { css } from 'styled-components';

import { Text } from '~components/primitives/Text';

// ─────────────────────────────────────────────────────────────────────────────
// helpers
// ─────────────────────────────────────────────────────────────────────────────

const StyledLink = styled(Text)`
  outline: none;

  &[aria-current] {
    font-weight: 700;
  }

  ${({ look }) => {
    if (look === 'primary') {
      return css`
        text-decoration: underline;
        text-decoration-color: var(--color-brand-primary);
        &:hover,
        &:focus,
        &:active {
          color: var(--color-brand-primary);
        }
      `;
    }
    if (look === 'secondary') {
      return css`
        &:hover,
        &:focus,
        &:active {
          color: var(--color-brand-primary);
          text-decoration: underline;
          text-decoration-color: var(--color-brand-primary);
        }
      `;
    }
    if (look === 'tertiary') {
      return css`
        opacity: 0.5;
        &:hover,
        &:focus,
        &:active {
          opacity: 1;
          /* color: var(--color-brand-primary); */
          text-decoration: underline;
          text-decoration-color: var(--color-brand-primary);
        }
      `;
    }

    return null;
  }};
`;

const parseLink = (link) => {
  if (link.startsWith('www.')) return `https://${link}`;
  if (!link.startsWith('mailto:') && link.includes('@')) {
    return `mailto:${link}`;
  }
  if (!link.startsWith('tel:') && link.includes('+')) return `tel:${link}`;
  return link;
};

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function Link({ href, to, children, ...rest }) {
  const link = href || to || '';
  if (
    ['http', 'mailto:', 'tel:', 'www.', '@', '+'].some(t => link.includes(t))
  ) {
    return (
      <StyledLink
        as={OutboundLink}
        href={parseLink(link)}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <StyledLink as={GatsbyLink} to={link} {...rest}>
      {children}
    </StyledLink>
  );
}

Link.propTypes = {
  children: node.isRequired,
  href:     string,
  to:       string,
};
Link.defaultProps = {
  href:       null,
  to:         null,
  fontWeight: 700,
};
