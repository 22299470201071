// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import styled from 'styled-components';

import { Text } from '~components/primitives/Text';

// ─────────────────────────────────────────────────────────────────────────────
// h1
// ─────────────────────────────────────────────────────────────────────────────

export const H1 = styled(Text)``;
H1.defaultProps = {
  as: 'h1',
  // fontSize:      '3rem',
  // lineHeight:    '4rem',
  // letterSpacing: '0.025em',
};

// ─────────────────────────────────────────────────────────────────────────────
// h2
// ─────────────────────────────────────────────────────────────────────────────

export const H2 = styled(Text)``;
H2.defaultProps = {
  as: 'h2',
  // fontSize:      '1.25rem',
  // fontWeight:    '300',
  // lineHeight:    '3rem',
  // textTransform: 'uppercase',
  // letterSpacing: '0.2em',
};

// ─────────────────────────────────────────────────────────────────────────────
// h3
// ─────────────────────────────────────────────────────────────────────────────

export const H3 = styled(Text)``;
H3.defaultProps = {
  as: 'h3',
  // fontSize:   '2.5rem',
  // lineHeight: '2.5rem',
};
