// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Header, H2, Link, Nav, Logo, Button } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function HeaderContainer({ location }) {
  const { header, headerEn } = useStaticQuery(graphql`
    query {
      header: mdx(
        fields: { sourceName: { eq: "menus" } }
        frontmatter: { title: { eq: "Header" } }
      ) {
        frontmatter {
          ...HeaderFragment
        }
      }
      headerEn: mdx(
        fields: { sourceName: { eq: "menus" } }
        frontmatter: { title: { eq: "Header - EN" } }
      ) {
        frontmatter {
          ...HeaderFragment
        }
      }
    }
  `);

  const menu = location.pathname.includes('/en/') ? headerEn : header;

  return (
    <Header>
      <Nav>
        <Link to="/" display="flex" padding="1rem">
          <Logo />
        </Link>
        <Nav.Toggle top="2rem" />
        <Nav.List>
          {menu?.frontmatter?.links.map((item) => {
            if (item.type === 'link') {
              return (
                <Nav.List.Item key={item.url}>
                  <Link
                    to={item.url}
                    display="block"
                    padding={{
                      xs: '1rem',
                      lg: '3rem 2rem',
                    }}
                  >
                    {item.text}
                  </Link>
                </Nav.List.Item>
              );
            }

            if (item.type === 'button') {
              return (
                <Nav.List.Item key={item.url}>
                  <Button as={Link} to={item.url} look="primary" padding="1rem 4rem">
                    {item.text}
                  </Button>
                </Nav.List.Item>
              );
            }

            if (item.type === 'nested') {
              return (
                <Nav.List.Item key={item.title}>
                  <H2
                    tabIndex="0"
                    outline="none"
                    padding={{
                      xs: '1rem',
                      lg: '3rem 2rem',
                    }}
                  >
                    {item.title}
                  </H2>
                  <Nav.Toggle />
                  <Nav.List>
                    {item.links.map(link => (
                      <Nav.List.Item key={link.url}>
                        <Link
                          to={link.url}
                          display="block"
                          padding={{
                            xs: '1rem',
                            lg: '1rem 4rem',
                          }}
                        >
                          {link.text}
                        </Link>
                      </Nav.List.Item>
                    ))}
                  </Nav.List>
                </Nav.List.Item>
              );
            }

            return null;
          })}
        </Nav.List>
      </Nav>
    </Header>
  );
}
