// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import { H1, Section, P, Button, Link, Video, Img } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function HeroContainer({ title, subtitle, body, heroImage, video, buttons }) {
  return (
    <Section
      gridColumn="1 / -1"
      padding={{
        xs: '5rem 1rem',
        md: '25vh 10vw 20vh',
        lg: '25vh 20vw 20vh',
      }}
      textAlign="center"
      position="relative"
    >
      <Img
        {...heroImage?.childImageSharp?.fluid}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        zIndex="-1"
        role="presentation"
        imgProps={{
          objectPosition: 'top left',
          mixBlendMode:   'luminosity',
          opacity:        '0.1',
        }}
        after={{
          content:         '""',
          position:        'absolute',
          width:           '100%',
          height:          '100%',
          top:             '0',
          left:            '0',
          zIndex:          '-1',
          backgroundImage:
            'linear-gradient(45deg, var(--color-brand-primary), var(--color-brand-secondary))',
        }}
      />
      {video && <Video src={video} />}
      <H1
        fontSize={{
          xs: '6rem',
          lg: '10rem',
        }}
        lineHeight="1"
        fontWeight="700"
        letterSpacing="-0.04em"
        margin="0 0 2rem"
        color="var(--color-inverse)"
      >
        {title}
      </H1>
      {subtitle && (
        <P
          fontSize="5rem"
          lineHeight="5rem"
          letterSpacing="-0.025em"
          color="var(--color-inverse)"
          margin="0 0 4rem"
        >
          {subtitle}
        </P>
      )}
      {buttons.length > 0
        && buttons.map(button => (
          <Button
            key={button.url}
            as={Link}
            to={button.url}
            look={button.look}
            display="inline-block"
            grouped
          >
            {button.title}
          </Button>
        ))}
    </Section>
  );
}

HeroContainer.propTypes = {
  title:    string.isRequired,
  subtitle: string,
  body:     string,
  video:    string,
  buttons:  arrayOf(
    shape({
      title: string.isRequired,
      url:   string.isRequired,
      look:  string.isRequired,
    }),
  ),
};

HeroContainer.defaultProps = {
  subtitle: '',
  body:     '',
  buttons:  [],
  video:    '',
};
