// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaTrophy } from 'react-icons/fa';
import { string } from 'prop-types';

import { H1, Section, P, Ul, Li, H2 } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function AwardsContainer({ title, subtitle, body, id }) {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fields: { sourceName: { eq: "awards" } }, frontmatter: { hidden: { ne: true } } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              company
              prizes {
                title
              }
            }
          }
        }
      }
    }
  `);

  const normalisedAwards = allMdx.edges.reduce(
    (acc, { node: { frontmatter: award } }) => [...acc, award],
    [],
  );

  return (
    <Section
      id={id}
      gridColumn="2"
      padding={{
        xs: '5rem 0',
        md: '10vh 0',
        lg: '15vh 0',
      }}
    >
      <H1
        fontSize={{
          xs: '6rem',
          lg: '8rem',
        }}
        lineHeight="1"
        fontWeight="700"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 2rem"
      >
        {title}
      </H1>
      <P
        fontSize="4rem"
        lineHeight="1"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 4rem"
      >
        {subtitle}
      </P>
      <Ul gridGap="2rem" maxWidth="80rem" margin="0 auto">
        {normalisedAwards.map((award) => (
          <Li
            key={award.title}
            borderRadius="1rem"
            backgroundColor="var(--color-inverse)"
            css={`
              --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
              box-shadow: var(--shadow);
            `}
            padding="2rem"
            textAlign="center"
          >
            <FaTrophy color="var(--color-brand-primary" />
            <H2 fontSize="3.5rem" fontWeight="700">
              {award.title}
            </H2>
            <P fontSize="2.5rem" lineHeight="1">
              {award.description}
            </P>
            <P fontSize="2.5rem" lineHeight="1" margin="0 0 2rem">
              {award.company}
            </P>
            <Ul gridGap="1rem">
              {award.prizes.map((prize) => (
                <Li key={prize.title}>
                  <P lineHeight="1">{prize.title}</P>
                </Li>
              ))}
            </Ul>
          </Li>
        ))}
      </Ul>
    </Section>
  );
}

AwardsContainer.propTypes = {
  title: string.isRequired,
  subtitle: string,
  body: string,
};

AwardsContainer.defaultProps = {
  subtitle: '',
  body: '',
};
