// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import {
  FaCalendarAlt,
  FaClipboardList,
  FaMoneyBillWave,
  FaMedal,
  FaBullhorn,
  FaComments,
} from 'react-icons/fa';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { string, arrayOf, shape } from 'prop-types';

import { H1, Section, P, Button, Link, Ul, Li, H2, H3, Img, View } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// helpers
// ─────────────────────────────────────────────────────────────────────────────

const iconProps = {
  fontSize: '6rem',
  color: 'var(--color-inverse)',
  style: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    // backgroundImage: 'radial-gradient(circle, var(--color-brand-primary) 5rem, transparent 5rem)',
    // padding:         '3rem',
  },
};
const iconSet = {
  comments: <FaComments {...iconProps} />,
  calendar: <FaCalendarAlt {...iconProps} />,
  list: <FaClipboardList {...iconProps} />,
  money: <FaMoneyBillWave {...iconProps} />,
  medal: <FaMedal {...iconProps} />,
  news: <FaBullhorn {...iconProps} />,
};

const renderIcons = (icons) => (
  <Ul
    display="flex"
    flexWrap="wrap"
    justifyContent="center"
    margin={{
      xs: '0 0 4rem',
      lg: '0 -5rem 0 -25rem',
    }}
  >
    {icons.map((icon) => (
      <Li
        key={icon.title}
        flex="0 0 25rem"
        margin="1rem"
        textAlign="center"
        transition="transform 250ms"
        // hover={{
        //   transform: 'scale(1.1)',
        // }}
      >
        <View
          position="relative"
          backgroundImage="linear-gradient(45deg,var(--color-brand-primary),var(--color-brand-secondary))"
          borderRadius="999px"
          width="50%"
          padding="0 0 50%"
          margin="0 auto 1rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {iconSet[icon.icon]}
        </View>
        <H2 fontSize="3rem" fontWeight="700">
          {icon.title}
        </H2>
        <P lineHeight="3rem">{icon.body}</P>
      </Li>
    ))}
  </Ul>
);

const renderTimeline = (timeline, layout) => (
  <Ul gridGap="1rem">
    {timeline.map((entry) => (
      <Li
        key={entry.date}
        textAlign={layout === 'right' && 'end'}
        padding="2rem"
        backgroundColor="var(--color-inverse)"
        css={`
          --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
          box-shadow: var(--shadow);
        `}
        borderRadius="1rem"
      >
        <H3 fontSize="4rem" fontWeight="700">
          {entry.title}
        </H3>
        <P fontSize="3rem">
          {new Date(entry.date).toLocaleString('cs-CZ', {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
          })}
        </P>
      </Li>
    ))}
  </Ul>
);

const renderSidekickImage = (sidekickImage) => (
  <Img
    {...sidekickImage?.childImageSharp?.fluid}
    alt=""
    role="presentation"
    ratio={1 / 1}
    css={`
      --shadow: 0 1rem 2rem hsla(var(--hsl-text), 0.25);
      box-shadow: var(--shadow);
    `}
    margin={{
      xs: '0 0 4rem',
      lg: '0',
    }}
    after={{
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '-1',
      backgroundImage:
        'linear-gradient(45deg, var(--color-brand-primary), var(--color-brand-secondary))',
    }}
    imgProps={{
      mixBlendMode: 'luminosity',
      opacity: '0.2',
      // isTilt:       true,
    }}
  />
);

const renderCards = (cards, layout) => (
  <Ul gridGap="1rem">
    {cards.map((card) => (
      <Li
        key={card.title}
        padding="2rem"
        backgroundColor="var(--color-inverse)"
        css={`
          --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
          box-shadow: var(--shadow);
        `}
        borderRadius="1rem"
        fontSize="2.5rem"
        lineHeight="1"
        textAlign={layout === 'right' && { lg: 'end' }}
      >
        <H3 fontSize="4rem" fontWeight="700" margin="0 0 1rem">
          {card.title}
        </H3>
        {card.markdown && <MDXRenderer>{card.markdown}</MDXRenderer>}
      </Li>
    ))}
  </Ul>
);

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function SidekickContainer({
  title,
  subtitle,
  body,
  id,
  buttons,
  sidekickImage,
  icons,
  timeline,
  cards,
  layout,
}) {
  return (
    <Section
      id={id}
      gridColumn="2"
      display="grid"
      gridTemplateColumns={{
        xs: '1fr',
        lg: '1fr 1fr',
      }}
      gridGap="0 10rem"
      alignItems="center"
      padding={{
        xs: '5rem 0',
        md: '10vh 0',
        lg: '15vh 0',
      }}
    >
      <View
        order={layout === 'right' && { lg: '2' }}
        margin={{ xs: '0 0 4rem', lg: '0' }}
        textAlign={{
          xs: '',
          lg: layout === 'right' ? undefined : 'end',
        }}
      >
        <H1
          fontSize={{
            xs: '6rem',
            lg: '8rem',
          }}
          lineHeight="1"
          fontWeight="700"
          letterSpacing="-0.025em"
          margin="0 0 2rem"
        >
          {title}
        </H1>
        {subtitle && (
          <P fontSize="4rem" lineHeight="1" letterSpacing="-0.025em" margin="0 0 4rem">
            {subtitle}
          </P>
        )}
        {body && (
          <P fontSize="2.5rem" margin="0 0 4rem">
            {body}
          </P>
        )}
        {buttons &&
          buttons.length > 0 &&
          buttons.map((button) => (
            <Button
              key={button.url}
              as={Link}
              to={button.url}
              look={button.look}
              display="inline-block"
              grouped
            >
              {button.title}
            </Button>
          ))}
      </View>
      {icons && renderIcons(icons)}
      {timeline && renderTimeline(timeline, layout)}
      {sidekickImage && renderSidekickImage(sidekickImage)}
      {cards && renderCards(cards, layout)}
    </Section>
  );
}

SidekickContainer.propTypes = {
  title: string.isRequired,
  subtitle: string,
  body: string,
  buttons: arrayOf(
    shape({
      title: string.isRequired,
      url: string.isRequired,
      look: string.isRequired,
    }),
  ),
  icons: arrayOf(
    shape({
      title: string.isRequired,
      body: string.isRequired,
      icon: string.isRequired,
    }),
  ),
  cards: arrayOf(
    shape({
      title: string.isRequired,
      body: string.isRequired,
    }),
  ),
};

SidekickContainer.defaultProps = {
  subtitle: '',
  body: '',
  buttons: [],
  icons: [],
  cards: [],
};
