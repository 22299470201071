// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { FaTrophy } from 'react-icons/fa';
import { string } from 'prop-types';

import { H1, Section, P, Ul, Li, H2, Link } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// helpers
// ─────────────────────────────────────────────────────────────────────────────

const renderStartup = startup => (
  <Li
    key={startup.url}
    borderRadius="1rem"
    backgroundColor="var(--color-inverse)"
    css={`
      --shadow: inset 0 0 0 2px hsla(var(--hsl-text), 0.05);
      box-shadow: var(--shadow);
    `}
    padding="2rem"
  >
    <P margin="0 0 1rem">
      <FaTrophy fontSize="1.5rem" color="var(--color-brand-primary)" />{' '}
      {startup.position}
    </P>
    <H2 fontSize="3rem" fontWeight="700">
      {startup.title}
    </H2>
    <P fontSize="2.5rem" lineHeight="1">
      {startup.owner}
    </P>
    <Ul margin="0 0 1rem" listStyle="disc" padding="0 0 0 1em">
      {startup.type && (
        <Li>
          <P lineHeight="1">Typ projektu: {startup.type}</P>
        </Li>
      )}
      {startup.industry && (
        <Li>
          <P lineHeight="1">Obor: {startup.industry}</P>
        </Li>
      )}
    </Ul>
    <P lineHeight="2.5rem" margin="0 0 1rem">
      {startup.description}
    </P>
    {startup.url && (
      <Link to={startup.url} look="primary">
        Více o {startup.title}
      </Link>
    )}
  </Li>
);

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function StartupsContainer({
  title,
  subtitle,
  body,
  id,
  startups,
}) {
  return (
    <Section
      id={id}
      gridColumn="2"
      padding={{
        xs: '5rem 0',
        md: '10vh 0',
        lg: '15vh 0 0',
      }}
    >
      <H1
        fontSize={{
          xs: '6rem',
          lg: '8rem',
        }}
        lineHeight="1"
        fontWeight="700"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 2rem"
      >
        {title}
      </H1>
      <P
        fontSize="4rem"
        lineHeight="1"
        letterSpacing="-0.025em"
        textAlign="center"
        margin="0 0 4rem"
      >
        {subtitle}
      </P>
      <Ul gridGap="2rem" maxWidth="80rem" margin="0 auto">
        {startups.map(renderStartup)}
      </Ul>
    </Section>
  );
}

StartupsContainer.propTypes = {
  title:    string.isRequired,
  subtitle: string,
  body:     string,
};

StartupsContainer.defaultProps = {
  subtitle: '',
  body:     '',
};
