// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React, { Fragment } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { HeroContainer,
  SidekickContainer,
  PartnersContainer,
  BlogContainer,
  PeopleContainer,
  AwardsContainer,
  StartupsContainer } from '~containers';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function renderBlocks(blocks) {
  return blocks.map((block) => {
    if (block.type === 'hero') {
      return <HeroContainer key={block.title} {...block} />;
    }

    if (block.type === 'sidekick') {
      return <SidekickContainer key={block.title} {...block} />;
    }

    if (block.type === 'partners') {
      return <PartnersContainer key={block.title} {...block} />;
    }

    if (block.type === 'people') {
      return <PeopleContainer key={block.title} {...block} />;
    }

    if (block.type === 'blog') {
      return <BlogContainer key={block.title} {...block} />;
    }

    if (block.type === 'awards') {
      return <AwardsContainer key={block.title} {...block} />;
    }

    if (block.type === 'startups') {
      return <StartupsContainer key={block.title} {...block} />;
    }

    if (block.type === 'mdx' || block.type === 'markdown') {
      return (
        <MDXRenderer key={block.mdx} components={{ wrapper: Fragment }}>
          {block.mdx}
        </MDXRenderer>
      );
    }
    return null;
  });
}
